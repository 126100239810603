import React, { Fragment, useCallback } from 'react'
import { TemplateProvider } from '@hooks/useTemplate'
import SEO from '@components/SEO'
import { navigate } from 'gatsby'
import FeedbackToUserAction from '@components/templates/FeedbackToUserAction'

const title: string = 'Token inválido'
const callToActionText: string = 'Ir a wicadu'
const description1: string = 'El token no es válido'

function InvalidTokenPage () {
  const handleNavigate = useCallback(() => {
    const { dataset } = event?.target || {}

    navigate(dataset?.url)
  }, [])

  return (
    <Fragment>
      <SEO title={title} />

      <TemplateProvider value={{
        title,
        description: [
          description1
        ],
        onNavigate: handleNavigate,
        callToActionText,
        type: 'error'
      }}>
        <FeedbackToUserAction />
      </TemplateProvider>
    </Fragment>
  )
}

export default InvalidTokenPage
